<template>
    <div>
        <competition-header
            v-model="currentCompetition"
            :showErrors="false"
            :showWarnings="false"
            :showBackButton="true"
        >
        </competition-header>

        <fieldset class="fieldset" >
            <legend>Général</legend>
            <b-form-group
                label="Type">
                <b-form-input readonly v-model="currentCompetition.COMPETITION_TYPE.name"/>
            </b-form-group>
            <b-form-group
                label="Nom de la compétition"
                v-if="!currentCompetition.COMPETITION_TYPE.isFederal">
                    <b-form-input type="text" v-model="newName" @change="newName_change"/>
            </b-form-group>
            <b-form-group
                label="Emplacement"
                v-if="!currentCompetition.COMPETITION_TYPE.isFederal">
                <b-form-input type="text" v-model="newLocation" class="location" @change="newLocation_change" />
            </b-form-group>
            <!--<b-form-group
                label="Est pour simulation uniquement"
                v-if="userHasRole(Roles.Tester)">
                <b-checkbox v-model="currentCompetition.isForSimulationPurposeOnly" disabled/>
            </b-form-group>-->
        </fieldset>

        <fieldset class="fieldset" v-if="currentCompetition.COMPETITION_TYPE.isPartOfCdfCva">
            <legend>{{ currentCompetition.COMPETITION_TYPE.longname }}</legend>
            <b-radio-group v-model="currentCompetition.scope" disabled>
                <b-radio value="nation">Finale</b-radio>
                <b-radio value="region">Manche(s) sélective(s)</b-radio>
            </b-radio-group>
            <b-form-group
                label="Année/saison">
                <b-form-input type="text" v-model="currentCompetition.year" :disabled="true"/>
                <span v-if="currentCompetition.scope == 'region'">comptant pour le CdF {{ currentCompetition.SEASON.yearSelectiveCdF }}</span>
            </b-form-group>
            <b-form-group
                label="Est Open"
                v-if="isTestMode">
                <b-form-checkbox v-model="newIsOpen" @change="newIsOpen_change" />
            </b-form-group>
            <b-form-group
                label="Lieu">
                <b-form-input type="text" class="location" v-model="newLocation" @change="newLocation_change"/>
            </b-form-group>
            <b-form-group
                label="Nom de la compétition">
                <b-form-input type="text" v-model="newName" @change="newName_change" />
            </b-form-group>
        </fieldset>

        <fieldset class="fieldset">
            <legend>Niveau(x)</legend>
            <b-form-checkbox-group
                label="Niveaux"
                v-model="selectedLevels"
                switches
                >
                <b-checkbox
                    v-for="level in levels"
                    :key="level.code"
                    :value="level"

                    :disabled="level.cannotBeUnselected"
                    @change="selectedLevels_changed(level)"
                >
                    {{ level.name }}
                </b-checkbox>
            </b-form-checkbox-group>
        </fieldset>

        <fieldset class="fieldset">
            <legend>Manches</legend>
            <b-form-group label="Nombre de manches">
                 <!--<b-form-input type="number" class="nbRounds" v-model="newNbRounds" :min="nb_Min_Rounds"  />-->
                 <number-of-rounds-editor
                  v-model="newNbRounds"
                  :minRoundsToKeep="nb_min_rounds"
                  :canRemoveRound="true"
                  :canAddRound="true"
                  @addRound="addRound"
                  @removeRound="removeRound"
                 >
                 </number-of-rounds-editor>
            </b-form-group>
            <b-form-group label="Règle de calcul des résultats">
                <b-select v-model="newRoundAverageComputationRule" size="sm" :disabled="newNbRounds <= 1">
                    <b-select-option v-if="newNbRounds == 0" value="">Non applicable</b-select-option>
                    <b-select-option v-if="newNbRounds > 0" value="averageOfAllRounds">Moyenne de toutes les manches</b-select-option>
                    <b-select-option v-if="newNbRounds > 0" value="averageOfNBestRounds">Moyenne des N meilleures manches de chaque pilote (overall)</b-select-option>
                    <b-select-option v-if="newNbRounds > 0" value="averageExcludingNWorseRounds">Moyenne des manches à l'exclusion des N moins bonnes manches de chaque pilote (overall)</b-select-option>
                </b-select>
            </b-form-group>
            <b-form-group
                :label="nbRoundToSkipOrKeepLabel"
                :disabled="!nbRoundToSkipOrKeepVisible || newNbRounds <= 1">
                <!--<b-form-input type="number" class="nbRounds" v-model="newNbRoundToSkipOrKeep"/>-->
                <number-of-rounds-editor
                  v-model="newNbRoundToSkipOrKeep"
                  :minRoundsToKeep="1"
                  :maxRounds="newNbRounds-1"
                  :canRemoveRound="true"
                  :canAddRound="true"
                  @addRound="addKeepableRounds"
                  @removeRound="removeKeepableRounds"
                />
            </b-form-group>
            <br/>
         </fieldset>

        <fieldset class="fieldset">
          <legend>Terrain(s)</legend>
          <b-tabs card>
            <b-tab v-for="field in fields" :key="field.id" :title="field.field.field_number + '-' + field.field.field_name">
              <template #title>
                <b-badge pill size="sm" :variant="field.field.isMain ? '' : 'info'">{{field.field.field_number}}</b-badge> {{ field.field.field_name}}
              </template>
              <field-editor
                :value="field.field"
                :canSetHotState="field.canChangeHotstate"
                :isRemovable="field.isRemovable"
                @fieldName_change="fieldName_change"
                @size_change="fieldSize_change"
                @isSonorized_change="fieldIsSonorized_change"
                @stageIn_change="fieldStageIn_change"
                @stageOut_change="fieldStageOut_change"
                @hotState_change="fieldHotState_change"
                @removeField="removeField"
              />
            </b-tab>
            <b-tab @click="addField" no-body>
              <template #title><i>Ajouter un terrain</i></template>
            </b-tab>
          </b-tabs>
        </fieldset>

         <fieldset class="fieldset">
            <legend>Membres de staff présents</legend>
            <detailed-staff-list
                    :items="staff"
                    :canSelect="currentCompetition.COMPETITION_TYPE.isPartOfCdfCva"
                    :showRollnumber="currentCompetition.COMPETITION_TYPE.isPartOfCdfCva"
                    :showCapabilities="true"
                    :canAdd="!currentCompetition.COMPETITION_TYPE.isPartOfCdfCva || newIsOpen && false"
                    :canEdit="!currentCompetition.COMPETITION_TYPE.isPartOfCdfCva || newIsOpen && false"
                    :canRemove="!currentCompetition.COMPETITION_TYPE.isPartOfCdfCva || newIsOpen && false"
                    @selectionChanged = "staffMemhers_selectionChanged"
            ></detailed-staff-list>
         </fieldset>

         <fieldset class="fieldset" v-if="selectedLevels.length > 0">
             <legend>Compétiteurs</legend>
             <b-form-checkbox v-model="disabledHiddenCategories" v-if="false" >Masquer les catégories inactives</b-form-checkbox>
             <level-categories-summary
                v-for="level in selectedLevels"
                :key="level.code"
                :level = level.name
             >
              <b-button
                  v-for="category in selectedLevelsCategory(level.code)"
                  :key="category.code"
                  @click="levelCategory_click(level, category)"
                  :variant="(editingCompetitorsLevel != null && editingCompetitorsLevel.code === level.code && editingCompetitorsCategory != null && editingCompetitorsCategory.code === category.code) ? 'primary' : ((levelCategoryCompetitors(level.code, category.code).length > 0) ? 'secondary' : 'outline-secondary')"
              >
                  <div class="categoryName"><icon-category v-model="category.code" :level="level.code" />{{ category.name }}</div>
                  <div class="categoryCompetitorsCount">({{ levelCategoryCompetitors(level.code, category.code).length }} inscrit{{ levelCategoryCompetitors(level.code, category.code).length > 1 ? "s" : "" }})</div>
              </b-button>

                <div v-if="editingCompetitorsLevel != null && editingCompetitorsLevel.code === level.code && editingCompetitorsDialogTitle != ''">
                    <h2>{{editingCompetitorsDialogTitle}}</h2>
                    <detailed-competitors-list
                        :showName = 'true'
                        :showRollnumber = "currentCompetition.COMPETITION_TYPE.isFederal && editingCompetitorsWithMembers == false"
                        :showMembers = "editingCompetitorsWithMembers"
                        :showMembersRollNumber = "currentCompetition.COMPETITION_TYPE.isFederal"
                        :items = 'editingCompetitorsList'
                        :canAdd = "!editingCompetitorsCategoryIsStarted"
                        :canWithdraw = "true"
                        :canEdit = "true"
                        :canRemove = "!editingCompetitorsCategoryIsStarted"
                        :canGiveUp = "!editingCompetitorsCategoryIsStarted"
                        :canChangePresence = "!editingCompetitorsCategoryIsStarted"
                        @add = 'addCompetitor'
                        @edit = 'editCompetitor'
                        @remove = 'removeCompetitor'
                        @withdrawState_change = 'competitorWithdrawChanged'
                        @forfeitState_change = 'competitorForfeitChanged'
                        @presenceState_change = 'competitorPresenceChanged'
                        ref="lstCompetitors2"
                    >
                    </detailed-competitors-list>
                </div>
             </level-categories-summary>
         </fieldset>

        <b-modal
            title="Ajout de compétiteur"
            centered
            :static="true"
            :lazy="true"
            v-model="showAddIndividualCompetitorModal"
            button-size="lg"
            @ok="editIndividualCompetitor_Ok"
        >
          <!--<b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="search" />
            </b-input-group-prepend>-->
            <vue-bootstrap-typeahead
                v-model="editCompetitorIndividualSearch"
                :data="editCompetitorIndividualSearchSuggestions"
                :minMatchingChars="2"
                :serializer="s => s.forSuggestion"
                placeholder="par nom, prénom, n° de licence"
                @hit="editIndividualSearch_Hit"
                prepend="Rechercher"
                ref="individualCompetitorSearchArea"
            >
            </vue-bootstrap-typeahead>
          <!--</b-input-group>-->
            <helper-note>
                Privilégiez toujours la recherche.<br/>
                Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
                Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou si vous êtes certain qu'il s'agit d'un nouveau compétiteur inconnu dans la base, saisissez les informations ci-dessous
            </helper-note>
            <b-form-group label="Prénom :">
                <b-form-input type="text"
                    v-model="editCompetitorFirstname"
                    required
                    :disabled="false"
                />
            </b-form-group>
            <b-form-group label="Nom :">
                <b-form-input
                    v-model="editCompetitorLastname"
                    required
                    :disabled="isForSimulationPurposeOnly"
                />
            </b-form-group>
            <b-form-group label="N° de licence FFVL">
                <b-form-input
                    v-model="editCompetitorFederalNumber"
                    :disabled="false"
                />
            </b-form-group>
        </b-modal>

        <b-modal
            title="Ajout de compétieur"
            centered
            :static="true"
            :lazy="true"
            v-model="showAddNonIndividualCompetitorModal"
            button-size="lg"
            @ok="editNonIndividualCompetitor_Ok"
        >
            <vue-bootstrap-typeahead
                v-model="editingCompetitorName"
                :data="editingCompetitorNameSuggestions"
                :placeholder="editingCompetitorsCategory.isPair ? 'Entrez le nom de la paire' : 'Entrez le nom de l\'équipe'"
                :minMatchingChars="1"
                prepend="Rechercher"
            />
<!--                @input="editNonIndividualCompetitorName_input"-->

            <helper-note>
                Privilégiez toujours la recherche.<br/>
                Si aucun résultat ne correspond, essayez de modifier l'orthographe.<br/>
                Si, finalement, vous ne trouvez vraiment pas le compétiteur, ou s'il s'agit d'un nouveau compétiteur inconnu dans la base, saisissez les informations ci-dessous
            </helper-note>
            <b-form-group label="Nom :" >
              <b-form-input
                v-model="editingCompetitorName"
                :disabled="!isTestMode"
              />
            </b-form-group>
            <b-form-group label="Co-équipiers" v-if="isTestMode">
                <team-members-list
                  :showName="true"
                  :showRollnumber="true"
                  :canAdd="true"
                  :canEdit="true"
                  :canRemove="true"
                  v-model="editingTeamCompetitor"
                  @search="addTeamMember"
                  v-if="isTestMode"
                >
                </team-members-list>
            </b-form-group>
        </b-modal>


      <b-modal
        title="Ajout d'équipier"
        :lazy="true"
        :static="true"
        v-model="showAddTeamCompetitorMemberModal"
        button-size="lg"
        @ok="editTeamCompetitorMember_Ok"
        ref="addTeamCompetitorMemberModal"
      >
        <choose-or-edit-people
          :searchSerializer="s => s.forSuggestion"
          :minMatchingChars="2"
          searchPlaceHolder="Par nom, prénom, numero de licence"
          searchPrepend="Rechercher"
          :searchData="editTeamCompetitorMemberSearchSuggestions"
        ></choose-or-edit-people>
      <!--"editTeamCompetitorMemberSearchSuggestions"-->
      </b-modal>

<!--<choose-or-edit-people-modal :show="show"></choose-or-edit-people-modal>-->
<!--<b-btn @click="addTeamMember">BTN</b-btn>-->

        <rounds-launcher
          :competition="currentCompetition"
          @roundEdit="round_edit"
          @roundStart="round_start"
        >
        </rounds-launcher>
<!--
        <choose-or-edit-people
          :searchSerializer="s => s.forSuggestion"
          :minMatchingChars="2"
          searchPlaceHolder="Par nom, prénom, numero de licence"
          searchPrepend="Rechercher"
          :searchData="editTeamCompetitorMemberSearchSuggestions"
        ></choose-or-edit-people>-->
    </div>
</template>

<script>
import {  REF_LEVEL,  SEASON_COMPETITION_TYPE_CATEGORY_LEVEL, /*RST_COMPETITION,*/ RST_COMPETITION_STAFF, RST_ROUND, /* RST_ROUND_COMPULSORY, RST_EVENT, */RST_COMPETITOR_COMPOSITION,/* RST_COMPETITOR_NOTES, RST_COMPETITOR_COMPULSORY_NOTE,*/ COMPETITOR, PEOPLE, RST_LEVEL,/* RST_CATEGORY, */RST_COMPETITOR, RST_FIELD } from "@/LocalDB";
import { mapGetters } from 'vuex';
import CompetitionHeader from '@/components/CompetitionHeader';
//import { RolesEnum } from "@/Constants";
import levelCategoriesSummary from "@/components/LevelCategoriesSummary";
import CompetitionsConfigurationHelper from '@/services/helpers/competitionsConfigurationHelper';
import DetailedStaffList from '@/components/DetailedStaffList';
import DetailedCompetitorsList from "@/components/DetailedCompetitorsList";
import ModelViewsHelper from "@/services/store/models/ModelViews";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import HelperNote from "@/components/HelperNote";
import RoundsLauncher from "@/components/RoundsLauncher";
import RoundsConfigurationHelper from "@/services/helpers/roundsConfigurationHelper";
import NumberOfRoundsEditor from '@/components/NumberOfRoundsEditor.vue';
import FieldEditor from '@/components/fieldEditor.vue';
import IconCategory from '@/components/IconCategory.vue';
import TeamMembersList from '@/components/TeamMembersList.vue';
//import ChooseOrEditPeopleModal from '@/components/ChooseOrEditPeopleModal.vue';
import ChooseOrEditPeople from '@/components/ChooseOrEditPeople.vue';

export default {
    components: { CompetitionHeader, levelCategoriesSummary, DetailedStaffList, DetailedCompetitorsList, VueBootstrapTypeahead, HelperNote, RoundsLauncher, NumberOfRoundsEditor, FieldEditor, IconCategory, TeamMembersList, /*ChooseOrEditPeopleModal,*/ ChooseOrEditPeople  },
    data(){

       return {
         show:false,
            //id: null,
            selectedLevels: [],/*
            currentCompetition: {
                id: null,
                name: '',
                location: '',
                scope: '',
                competition_type: '',
                isForSimulationPurposeOnly: false,
                isOpen: false,
            },*/
            disabledHiddenCategories: false,

            editingCompetitorsDialogTitle: '',
            editingCompetitorsLevel: '',
            editingCompetitorsCategory: '',
            editingCompetitorsWithMembers: false,
            editingCompetitorsList: [],

            editingCompetitorId: '',
            showAddIndividualCompetitorModal: false,
            showAddNonIndividualCompetitorModal: false,
            editingTeamCompetitor: {
              id: '',
              isPair: true,
              isTeam: false,
              isDualLines: true,
              isMultiline: false,
              members:[]
            },
            editingCompetitorName: '',
            editCompetitorIndividualSearch: '',
            editCompetitorIndividualSearchSuggestions: [],
            editingCompetitorNameSuggestions: [],
            editCompetitorFirstname: '',
            editCompetitorLastname : '',
            editCompetitorFederalNumber: '',

            staff: [],

            showAddTeamCompetitorMemberModal: false,
            editTeamCompetitorMemberSearchSuggestions: []
        }
    },
    computed: {
        newName: {
            get(){ return this.currentCompetition.name; },
            set(val) { this.currentCompetition.name = val; }
        },
        newLocation: {
            get(){ return this.currentCompetition.location; },
            set(val){ this.currentCompetition.location = val; }
        },
        newIsOpen: {
            get(){ return this.currentCompetition.isOpen; },
            set(val){ this.currentCompetition.isOpen = val; }
        },
        newNbRounds: {
            get(){ return RST_ROUND.query().where('competition_id', this.currentCompetition.id).get().length; },
            set(val){ console.log('new rounds length', val); }
        },
        nb_min_rounds: {
          get() {
            var min = RST_ROUND.query().where('competition_id', this.currentCompetition.id).where('isStarted', true).get().length;
            if(min == 0)
              min = 1;
            return min;
          }
        },
        newRoundAverageComputationRule: {
            get(){return this.currentCompetition.roundAverageComputationRule},
            set(newValue){
              this.$showRefresher()
                .then(() => {
                  CompetitionsConfigurationHelper.changeRoundAverageComputationRule(this.currentCompetition_id, newValue);
                  this.$hideRefresher();
                });
              }
        },
        newNbRoundToSkipOrKeep :{
            get() { return this.currentCompetition.nbRoundToSkipOrKeep; },
            set(newValue){
              this.$showRefresher()
                .then(() => {
                  CompetitionsConfigurationHelper.changeNbRoundToSkipOrKeep(this.currentCompetition_id, newValue);
                  this.$hideRefresher();
                });
            }
        },
        nbRoundToSkipOrKeepVisible(){
            return this.newRoundAverageComputationRule != "averageOfAllRounds";
        },
        nbRoundToSkipOrKeepLabel(){
            if(this.newRoundAverageComputationRule == "averageOfNBestRounds")
                return "Nb. manches conservées";
            if(this.newRoundAverageComputationRule == "averageExcludingNWorseRounds")
                return "Nb. manches exclues";
            return "Nb. manches considérées";
        },
        levels(){
            var ret = [];
            SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
                .where('year', this.currentCompetition.year)
                .where('competition_type', this.currentCompetition.competition_type)
                .get()
                .map(item => {
                  return item.level
                })
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .forEach(code => {
                    var toPush = REF_LEVEL.query().where('code', code).first();
                    ret.push(toPush);
                });
            return ret;
        },
        editingCompetitorsCategoryIsStarted(){
          return RoundsConfigurationHelper.isCategoryStarted(this.currentCompetition_id, this.editingCompetitorsLevel, this.editingCompetitorsCategory);
        },
        fields(){
          return RST_FIELD.query()
            .where("competition_id", this.currentCompetition_id)
            .orderBy("field_number")
            .get()
            .map(item => {
              return { field: item, isRemovable: !item.isMain, canChangeHotState : false }
            })
        }
    },
    methods:{
        ...mapGetters('modelViewsHelper', ['getDetailedStaffListItems'] ),
        saveChanges_Click(){
            console.log('TODO => implement saveChanges_Click in CompetitionEdit.vue');
        },
        newName_change(newValue)
        {
          this.$showRefresher()
            .then(()=>{
              CompetitionsConfigurationHelper.renameCompetition(this.currentCompetition_id, newValue);
              this.$hideRefresher();
          });
        },
        newLocation_change(newValue) {
          this.$showRefresher()
            .then(()=> {
              CompetitionsConfigurationHelper.changeCompetitionLocation(this.currentCompetition_id, newValue);
              this.$hideRefresher();
          });
        },
        newIsOpen_change(newValue)
        {
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.changeIsOpenFlag(this.currentCompetition_id, newValue);
              this.$hideRefresher();
          });
        },
/*        newRoundAverageComputationRule_change(newValue)
        {
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.changeRoundAverageComputationRule(this.currentCompetition_id, newValue);
              this.$hideRefresher();
            })
        },
        newNbRoundToSkipOrKeep_change(newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.changeNbRoundToSkipOrKeep(this.currentCompetition_id, newValue);
              this.$hideRefresher();
            })
        },*/

        refresh(){
            this.selectedLevels = RST_LEVEL.query()
                .where('competition_id', this.currentCompetition.id)
                .get()
                .map(item => item.level )
                .filter((value, index, self) => { return self.indexOf(value) === index})
                .map(item => { return REF_LEVEL.query().where("code", item).first()});
                this.staff = ModelViewsHelper.getDetailedStaffListItems(this.currentCompetition.competition_type, this.currentCompetition.scope,this.currentCompetition.year, null)
                            .filter(item => item.isFederalActive)
                            .map(item => {
                                var st = RST_COMPETITION_STAFF.query().where('competition_id', this.currentCompetition.id).where('staff_id', item.id).first();
                                if(st != null){
                                    item.isSelected = true;
                                    item.cannotBeSelected = st.hasAction;
                                }
                                return item;
                            });
        },
        selectedLevels_changed(e){
          // recherche du niveau changé dans la liste des niveaux choisis
          var estASupprimer = this.selectedLevels.findIndex(l => l.code == e.code) < 0;
          if(estASupprimer)
          {
            // Le niveau vient d'être supprimé de la liste. Il faut donc le supprimer en base (avec tout ce qui va avec -> catégories & compétiteurs, notamment)
            // Cependant, on demande confirmation, au préalale si des compétiteurs ont déjà été ajoutés pour ce niveau.
            this.$showRefresher()
              .then(() => {
                CompetitionsConfigurationHelper.deleteLevel(this.currentCompetition_id, e.code);
                this.$hideRefresher();
              })
          } else {
            // Le niveau vient d'être ajouté. Il faut donc l'ajouter en base avec (avec tout ce qui va avec -> catégories)
            this.$showRefresher()
              .then(() => {
                CompetitionsConfigurationHelper.createLevel(this.currentCompetition_id, e.code);
                this.$hideRefresher();
              });
          }
        },
        selectedLevelsCategory(levelCode){
            var ret = SEASON_COMPETITION_TYPE_CATEGORY_LEVEL.query()
                .where('year', this.currentCompetition.year)
                .where('competition_type', this.currentCompetition.competition_type)
                .where('level', levelCode)
                .get()
                .map(item => item.CATEGORY)
                .filter((value, index, self) => { return self.indexOf(value) === index})
            return ret;
        },
        addField(){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.addField(this.currentCompetition_id);
              this.$hideRefresher();
          });
        },
        removeField(field_number) {
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.removeField(this.currentCompetition_id, field_number);
              this.$hideRefresher();
            });
        },
        fieldName_change(field_number, newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldName(this.currentCompetition_id, field_number, newValue);
              this.$hideRefresher();
            });
        },
        fieldSize_change(field_number, newSize_A, newSize_B)
        {
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldSize(this.currentCompetition_id, field_number, newSize_A, newSize_B);
              this.$hideRefresher();
            });
        },
        fieldIsSonorized_change(field_number, newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldIsSonorized(this.currentCompetition_id, field_number, newValue);
              this.$hideRefresher();
            });
        },
        fieldStageIn_change(field_number, newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldStageIn(this.currentCompetition_id, field_number, newValue);
              this.$hideRefresher();
            });
        },
        fieldStageOut_change(field_number, newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldStageOut(this.currentCompetition_id, field_number, newValue);
              this.$hideRefresher();
            });
        },
        fieldHotState_change(field_number, newValue){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.setFieldHotState(this.currentCompetition_id, field_number, newValue);
              this.$hideRefresher();
            });
        },
        addRound(){
          this.$showRefresher()
            .then(() => {
              var addedRound = RoundsConfigurationHelper.createRound(this.currentCompetition_id);
              this.selectedLevels.forEach(levelItem => {
                RoundsConfigurationHelper.createLevel(this.currentCompetition_id, addedRound.round_number, levelItem.code);
                this.selectedLevelsCategory(levelItem.code).forEach(categoryItem => {
                  RoundsConfigurationHelper.createCategory(this.currentCompetition_id, addedRound.round_number, levelItem.code, categoryItem.code);
                });
              });
              this.$hideRefresher();
            });
        },
        removeRound(){
          this.$showRefresher()
            .then(() => {
              RoundsConfigurationHelper.deleteRound(this.currentCompetition_id);
              this.$hideRefresher();
            });
        },
        addKeepableRounds(){
          this.newNbRoundToSkipOrKeep++;
        },
        removeKeepableRounds(){
          this.newNbRoundToSkipOrKeep--;
        },
        staffMemhers_selectionChanged(element){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.toggleStaffMemberPresence(this.currentCompetition_id, element.id);
              this.$hideRefresher();
            });
        },
        levelCategoryCompetitors(levelCode, categoryCode){
          //var categoryStarted = RoundsConfigurationHelper.isCategoryStarted(this.currentCompetition_id, levelCode, categoryCode);
            return RST_COMPETITOR.query()
                .where("competition_id", this.currentCompetition.id)
                .where("level", levelCode)
                .where("category", categoryCode)
                .where("round_number", null)
                .orderBy("subscription_order")
                .get()
                .map(mapped => {
                    var item = mapped.COMPETITOR;
                    item.isWithdrawn = mapped.isWithdrawn;
                    item.isForfeited = mapped.isForfeited;
                    item.isAbsent = mapped.isAbsent;
                    //if(this.currentCompetition.isStarted)
                    //{
                      item.canWithdraw = !mapped.hasAtLeastOneNote();
                      item.canBeRemoved = !mapped.hasAtLeastOneNote();
                      item.canGiveUp = !mapped.hasAtLeastOneNote();
                      item.canChangePresence = !mapped.hasAtLeastOneNote();
                    //}
                    if(mapped.COMPETITOR.isIndividual)
                      item.federal_number = mapped.COMPETITOR.PEOPLE.federal_number;
                    return item;
                });
        },
        levelCategory_click(level, category){
            var newTitle = 'Compétiteurs en ' + category.name + ' ' + level.name;
            if(this.editingCompetitorsDialogTitle === newTitle)
            {
                this.editingCompetitorsDialogTitle = '';
                this.editingCompetitorsLevel = null;
                this.editingCompetitorsCategory = null;
                this.editingCompetitorsList = null;
            }
            else
            {
                this.editingCompetitorsDialogTitle = newTitle;
                this.editingCompetitorsLevel = level;
                this.editingCompetitorsCategory = category;
                this.editingCompetitorsWithMembers = !category.isIndividual;
                this.editingCompetitorsList = this.levelCategoryCompetitors(level.code, category.code);
                //this.showCompetitorsModal = true;
            }
        },
        addTeamMember(e){
          console.log("e", e);
          var isDualLines = this.editingCompetitorsCategory.isDualLines;
          this.editTeamCompetitorMemberSearchSuggestions = COMPETITOR.query()
                    .where('competition_type', this.currentCompetition.competition_type)
                    .where(item => item.CATEGORY.isDualLines == isDualLines)
                    .where('isIndividual', true)
                    .get()
                    .map(item => item.PEOPLE)
                    .filter(item => {
                      return !RST_COMPETITOR_COMPOSITION.query()
                                .where(member => {return item.id == member.people_id
                                                      && member.competition_id == this.currentCompetition_id
                                                      && member.COMPETITOR.category == this.editingCompetitorsCategory.code
                                                      && member.level == this.editingCompetitorsLevel.code
                                }).exists()

                    }).map(item => {
                        var mapped = item;
                        mapped.forSuggestion = '** ' + item.name + ((item.isFederal == true) ?  ' - ' + item.federal_number : '');
                        return mapped;
                    });
                COMPETITOR.query()
                    .where('competition_type', this.currentCompetition.competition_type)
                    .where(item => item.CATEGORY.isDualLines != isDualLines)
                    .where('isIndividual', true)
                    .get()
                    .map(item => item.PEOPLE)
                    .forEach(comp => {
                        if(this.editTeamCompetitorMemberSearchSuggestions.findIndex(item => item.id == comp.people_id) < 0 )
                        {
                          if(!RST_COMPETITOR.query()
                                .where(member => {return comp.id == member.people_id
                                                      && member.COMPETITOR.competition_id == this.currentCompetition_id
                                                      && member.COMPETITOR.category == this.editingCompetitorsCategory.code
                                                      && member.COMPETITOR.level == this.editingCompetitorsLevel.code
                                }).exists()){
                              var mapped = comp;
                              //mapped.id = null;
                              mapped.forSuggestion = '* ' + mapped.name + ((mapped.isFederal == true) ? ' - ' + mapped.federal_number : '');
                              this.editTeamCompetitorMemberSearchSuggestions.push(mapped);
                          }
                        }
                    });

                this.showAddTeamCompetitorMemberModal = true;
        },
        addCompetitor(){
            this.editingCompetitorId = null;
            if(this.editingCompetitorsCategory.isIndividual)
            {
                this.editingCompetitorId = null;
                this.editCompetitorFirstname = '';
                this.editCompetitorLastname = '';
                this.editCompetitorFederalNumber = '';
                this.editCompetitorIndividualSearch = '';
                this.editCompetitorIndividualSearchSuggestions = COMPETITOR.query()
                    .where('competition_type', this.currentCompetition.competition_type)
                    .where('category', this.editingCompetitorsCategory.code)
                    .where('isIndividual', true)
                    .get()
                    .filter(item => {
                      return !RST_COMPETITOR.query()
                                .where("competition_id", this.currentCompetition_id)
                                .where('category', this.editingCompetitorsCategory.code)
                                .where('level', this.editingCompetitorsLevel.code)
                                .where('competitor_id', item.id)
                                .exists();
                    })
                    .map(item => {
                        var mapped = item;
                        mapped.forSuggestion = '** ' + item.name + ((item.isFederal == true) ?  ' - ' + item.PEOPLE.federal_number : '');
                        return mapped;
                    });
                COMPETITOR.query()
                    .where('competition_type', this.currentCompetition.competition_type)
                    .where(item => item.category != this.editingCompetitorsCategory.code)
                    .where('isIndividual', true)
                    .get()
                    .forEach(comp => {
                        if(this.editCompetitorIndividualSearchSuggestions.filter(item => item.people_id == comp.people_id).length == 0)
                        {
                          if(!RST_COMPETITOR.query()
                                .where("competition_id", this.currentCompetition_id)
                                .where('category', this.editingCompetitorsCategory.code)
                                .where(element => element.COMPETITOR.people_id == comp.people_id)
                                .exists()){
                              var mapped = comp;
                              mapped.id = null;
                              mapped.forSuggestion = '* ' + comp.name + ((comp.isFederal == true) ? ' - ' + comp.PEOPLE.federal_number : '');
                              this.editCompetitorIndividualSearchSuggestions.push(mapped);
                          }
                        }
                    });
                PEOPLE.query()
                    .get()
                    .forEach(people => {
                        if(this.editCompetitorIndividualSearchSuggestions.filter(item => item.people_id == people.id).length == 0)
                        {
                          if(!RST_COMPETITOR.query()
                                .where("competition_id", this.currentCompetition_id)
                                .where('category', this.editingCompetitorsCategory.code)
                                .where(element => element.COMPETITOR.people_id == people.id)
                                .exists()){
                              var mapped = {
                                  id: null,
                                  name: people.name,
                                  shortname: people.shortname,
                                  //category: this.editingCompetitorsCategory.code,
                                  //competition_type: this.currentCompetition.competition_type,
                                  federal_number: people.federalNumber,
                                  firstname: people.firstname,
                                  lastname: people.lastname,
                                  sexe: people.sexe,
                                  people_id: people.id,
                                  isIndividual: true,
                                  PEOPLE: people,
                                  forSuggestion: people.name + ((people.isFederal == true) ? ' - ' + people.federalNumber : ''),
                              }
                              this.editCompetitorIndividualSearchSuggestions.push(mapped);
                          }
                        }
                    });
                    //this.showAddTeamCompetitorMemberModal = true
                this.showAddIndividualCompetitorModal = true;
                this.$nextTick(() => {
                    this.$refs.individualCompetitorSearchArea.$el.focus();
                });
            } else {
                this.editingCompetitorName = '';
                this.editingCompetitorNameSuggestions = COMPETITOR.query()
                    .where('competition_type', this.currentCompetition.competition_type)
                    .where('category', this.editingCompetitorsCategory.code)
                    .get()
                    .map(item => item.name);
                this.editingTeamCompetitor = {
                  isPair: this.editingCompetitorsCategory.isPair,
                  isTeam: this.editingCompetitorsCategory.isTeam,
                }
                this.showAddNonIndividualCompetitorModal = true;
            }
        },
        editCompetitor(competitor){
            console.log('editCompetitor', competitor);
            alert('Not implemented yet !');
        },
        removeCompetitor(competitor){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.dettach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.$hideRefresher();
            });
        },
        competitorWithdrawChanged(competitor){
          this.$showRefresher()
            .then(() =>{
              CompetitionsConfigurationHelper.changeCompetitor_withdraw(this.currentCompetition_id, competitor.id, competitor.isWithdrawn);
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
             this.$hideRefresher();
            })
        },
        competitorForfeitChanged(competitor){
          this.$showRefresher()
            .then(()=>{
              CompetitionsConfigurationHelper.changeCompetitor_forfeit(this.currentCompetition_id, competitor.id, competitor.isForfeited);
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.$hideRefresher();
            });
        },
        competitorPresenceChanged(competitor){
          this.$showRefresher()
            .then(() => {
              CompetitionsConfigurationHelper.changeCompetitor_presence(this.currentCompetition_id, competitor.id, competitor.isAbsent)
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.$hideRefresher();
            });
        },
        editIndividualSearch_Hit(selected){
            this.editingCompetitorId = selected;
            this.editCompetitorFirstname = selected.PEOPLE.firstname;
            this.editCompetitorLastname = selected.PEOPLE.lastname;
            this.editCompetitorFederalNumber = selected.PEOPLE.federal_number;
        },
        editTeamCompetitorMember_Ok(selection){
          this.$showRefresher()
            .then(()=>{
                if(selection.selected != null)
                {
                    console.log('editTeamCompetitorMemer_ok', selection);
                    this.$hideRefresher();
                }
            });
        },

        editIndividualCompetitor_Ok(){
          this.$showRefresher()
            .then(() => {
              var comp = this.editingCompetitorId;

              var catCompetitors = RST_COMPETITOR.query()
                      .where("competition_id", this.currentCompetition.id)
                      .where("level", this.editingCompetitorsLevel.code)
                      .where("category", this.editingCompetitorsCategory.code)
                      .orderBy("subscription_order")
                      .get();
              var lastCatCompetitor = (catCompetitors != null && catCompetitors.length > 0)
                                    ? catCompetitors[catCompetitors.length - 1]
                                    : null;
              var subscriptionOrder = (lastCatCompetitor != null)
                                    ? lastCatCompetitor.subscription_order
                                    : 0;

              if(comp != null && comp.category == this.editingCompetitorsCategory.code)
              {
                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, comp.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
              } else if (comp != null) {
                  var competitor = CompetitionsConfigurationHelper.create_new_individual_competitor_from_people(this.currentCompetition.competition_type, this.editingCompetitorsCategory.code, comp.people_id );
                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
              } else {
                  competitor = CompetitionsConfigurationHelper.create_new_individual_competitor_from_scratch(this.currentCompetition.competition_type,
                      this.editingCompetitorsCategory.code,
                      this.editCompetitorFirstname,
                      this.editCompetitorLastname,
                      this.editCompetitorFederalNumber);
                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
              }
              this.showAddIndividualCompetitorModal = false;
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.$hideRefresher();
            });
        },
       /* editNonIndividualCompetitorName_input(name){
            /*var comp = COMPETITOR.query()
                .where('competition_type', this.competitionType)
                .where('category', this.editingCompetitorsCategory.code)
                .where('name', this.name)
                .get()[0];*/
       /*     //console.log('editNonIndividualCompetitorName_input', name);
        },*/
        editNonIndividualCompetitor_Ok(){
          this.$showRefresher()
            .then(() => {
              var comp = COMPETITOR.query()
                  .where('competition_type', this.currentCompetition.competition_type)
                  .where('category', this.editingCompetitorsCategory.code)
                  .where('name', this.editingCompetitorName)
                  .first();

              var catCompetitors = RST_COMPETITOR.query()
                      .where("competition_id", this.currentCompetition.id)
                      .where("level", this.editingCompetitorsLevel.code)
                      .where("category", this.editingCompetitorsCategory.code)
                      .orderBy("subscription_order")
                      .get();
              var lastCatCompetitor = (catCompetitors != null && catCompetitors.length > 0)
                                    ? catCompetitors[catCompetitors.index - 1]
                                    : null;
              var subscriptionOrder = (lastCatCompetitor != null)
                                    ? lastCatCompetitor.subscription_order
                                    : 0;

              if(comp != null)
              {
                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, comp.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
              } else {
                  var competitor = CompetitionsConfigurationHelper.create_new_non_individual_competitor(this.currentCompetition.competition_type, this.editingCompetitorsCategory.code, this.editingCompetitorName);
                  CompetitionsConfigurationHelper.attach_competitor(this.currentCompetition_id, competitor.id, this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code, subscriptionOrder + 1);
              }
              this.showAddNonIndividualCompetitorModal = false;
              this.editingCompetitorsList = this.levelCategoryCompetitors(this.editingCompetitorsLevel.code, this.editingCompetitorsCategory.code);
              this.$hideRefresher();
            });
        },
        round_edit(round){
          this.$showLoader()
            .then(()=>{
            this.setCurrentRound_number(round.round_number);
            this.$router.push({ name: "RoundPrepare"});
          });
        },
        round_start(round){
          this.$showLoader()
            .then(() => {
              this.setCurrentRound_number(round.round_number);
              this.$router.push({ name: "RoundPrepare"});
          });
        }
    },
    mounted(){
        this.checkSynchro();
        this.refresh();
        this.$hideLoader();
    },
}
</script>
