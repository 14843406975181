<template>
    <div>
      <b-button
        class="launcher"
        v-for="round in rounds"
        :key="round.round_number"
        @click="roundSelected(round)"
        :variant="variant(round)"
      >
        <div v-if="label(round).prefix != null" class="prefix">{{ label(round).prefix }}</div>
        <div class="mainText"> {{ label(round).text }}</div>
        <div v-if="label(round).suffix != null" class="suffix">{{ label(round).suffix }}</div>
      </b-button>
    </div>
</template>

<script>
import { RST_ROUND, RST_COMPETITOR } from "@/LocalDB";

export default {
    props: {
        competition: { type: Object, required: true },
    },

    computed: {
        rounds(){
            var ret = RST_ROUND.query()
                            .where("competition_id", this.competition.id)
                            .get()
                            .filter((value, index, self) => { return self.indexOf(value) === index});
            //console.log('rounds', ret);
            return ret;
        }
    },
    methods:{
      variant(round){
        if(round.isCompleted)
          return "success";
        if(!this.hasCompetitors())
          return "outline-dark"
        if(round.round_number > 1 && !this.rounds.filter(item => item.round_number == round.round_number - 1).isStarted)
          return "secondary";
        return "primary";
      },
      roundSelected(round)
      {
        if(round.isCompleted)
          this.$emit('roundViewResult', round);
        else if(round.isStarted)
          this.$emit('roundContinue', round);
        if(round.round_number > 1 && !this.rounds.filter(item => item.round_number == round.round_number - 1).isStarted)
          this.$emit('roundEdit', round);
        this.$emit('roundStart', round);
      },
      hasCompetitors()
      {
        return RST_COMPETITOR.query().where('competition_id', this.competition.id)/*.where('round_number', round.round_number)*/.exists();
      },

      label(round)
      {
        if(round.isCompleted)
          return { prefix: "Résultats de", text:"la manche " + round.round_number, suffix: '' };
        if(!this.hasCompetitors(round))
          return { prefix: '', text:"Manche " + round.round_number, suffix: "(pas encore d'inscrits)"};
        if(round.isStarted)
          return { prefix: 'Reprendre', text: "la manche" + round.round_number, suffix: ""};
        if(round.round_number > 1 && !this.rounds.filter(item => item.round_number == round.round_number - 1).isStarted)
          return { prefix : "", text: "Manche " + round.round_number, suffix: ""};
        return { prefix:"Démarrer", text: "la manche " + round.round_number, suffix: ""};
      }
    }
}
</script>

<style scoped>
  .launcher { width: 150px; height: 150px; margin:10px; }

  .prefix { margin-bottom: 5px;}
  .mainText { font-size: 1.3rem; font-weight:bolder; }
  .suffix { font-size: 0.8rem; font-style:italic; margin-top: 5px; }
</style>
