<template>
    <div>
        <slot name="header"></slot>
        <h4>{{ level }}</h4>
        <slot>
            <b-button
                v-for="category in categories"
                :key="category.code"
                @click="category_clicked(category.code)"
            >
                {{ category.name }}
            </b-button>
        </slot>
        <slot name="footer"></slot>
    </div>
</template>

<script>

export default {
    props: {
        level: { type: String, default:''},
        categories: [],
    },
    data(){
      return {
      }
    },
    methods: {
        category_clicked(category)
        {
            this.$emit('CategoryClick', category);
        }
    }
}
</script>
